var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "show")
    ? _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c(
            "b-overlay",
            {
              attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "overlay",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center mt-5" },
                          [
                            _c("base-icon", {
                              attrs: {
                                name: "loading",
                                width: "35",
                                height: "35",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", { attrs: { id: "cancel-label" } }, [
                              _vm._v("Operazione in corso..."),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3374301958
              ),
            },
            [
              !_vm.isLoading
                ? _c(
                    "b-card",
                    {
                      staticClass: "mt-2",
                      attrs: { header: "true", "header-tag": "header" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between align-items-center",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-0 d-inline-block align-middle" },
                            [_c("b", [_vm._v("Dati di Agenzia")])]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("table", [
                        _c("tr", [
                          _c("th", [_vm._v("Azioni")]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.getDictionary(
                                    "attribute_CNAM_value",
                                    "agency_setup"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.getDictionary(
                                    "attribute_CREG_value",
                                    "agency_setup"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.getDictionary(
                                    "attribute_NINO_value",
                                    "agency_setup"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.getDictionary(
                                    "attribute_ADDR_value",
                                    "correspondence"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.getDictionary(
                                    "attribute_PHON_value",
                                    "address_book"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.getDictionary(
                                    "attribute_MAIL_value",
                                    "address_book"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.getDictionary(
                                    "attribute_SMSFROM_value",
                                    "address_book"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.getDictionary(
                                    "attribute_SIGNMAIL_value",
                                    "address_book"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "action-buttons" },
                              [
                                _vm.canVerb(_vm.resource, "edit")
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "mt-1 mr-1",
                                        attrs: {
                                          size: "sm",
                                          variant: "lisaweb",
                                          title: "Modifica",
                                        },
                                        on: { click: _vm.onEdit },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "pencil-square" },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(_vm.details.attributables.CNAM)),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(_vm.details.attributables.CREG)),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(_vm.details.attributables.NINO)),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.toRelationString(
                                    "correspondences",
                                    _vm.details["correspondences"],
                                    ["ADDR"],
                                    [0]
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.toRelationString(
                                    "address_books",
                                    _vm.details["address_books"],
                                    ["PHON"],
                                    [0]
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.toRelationString(
                                    "address_books",
                                    _vm.details["address_books"],
                                    ["MAIL"],
                                    [0]
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.toRelationString(
                                    "address_books",
                                    _vm.details["address_books"],
                                    ["SMSFROM"],
                                    [0]
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.toRelationString(
                                    "address_books",
                                    _vm.details["address_books"],
                                    ["MAILFROM"],
                                    [0]
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }